(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name PostController
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('PostController', PostController);

    PostController.$inject = ["PostService"];

    function PostController(PostService)
    {
        var vm = this;
        vm.page = 1;
        vm.maxPages = 0;
        vm.offset = 12;
        vm.totalItems = 0;

        vm.getpost = function(){
            PostService.getPost(vm).then(function(data){
                vm.posts = data.posts;
                vm.maxPages = data.maxPages;
                vm.totalItems = data.items;
            });
        }

        vm.randompost = function(){
            PostService.getPostrand(vm).then(function(data){
                vm.posts = data.posts;

            });
        }

        vm.next = function(){
            vm.page++;
            vm.getpost();
        };

        vm.prev = function(){
            vm.page--;
            vm.getpost();
        };

        vm.setPage = function (pageNo) {
            vm.getpost();
        };

    }


})();