// (function(){
//     'use strict';

// /**
// * @memberof app
// * @ngdoc controller
// * @name CurriculumController
// * @description
// * This is main controller
// **/ 

//     angular
//     .module('app')
//     .controller('CurriculumController', CurriculumController);

//     CurriculumController.$inject = ["CurriculumService"];

//     function CurriculumController(CurriculumService)
//     {

//     	var vm = this;
//         console.log("CV");
//       	vm.registroopenwork = function(form){
//           console.log("envio inicio");

//               if (!form.$valid) {
//                   angular.element("[name='" + form.$name + "']").find('.ng-invalid:visible:first').focus();
//                   return false;
//               }

//       		CurriculumService.sendCV(vm.registro,function(data){
//               vm.msg = data.msg;

//               });
//       	}

//     }


// })();