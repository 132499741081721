(function(){
    angular
        .module('app')
        .directive('ngInterceptlink', interceptarlinks);

        interceptarlinks.$inject = ['$timeout'];
        function interceptarlinks($timeout){
            return {
            link: function (scope, element, $attrs) {
                return $timeout(function () {
                    // console.log("interceptando");
                    $(document).on("click","a[target!='_blank']",function(){
                        //alert("E");
                        pageTransitionIn();
                        var self = this;
                        $timeout(function(){
                            window.location.href = $(self).attr("href");
                        },1000)
                        return false;
                    })
                });
            }
          }
      }
})();