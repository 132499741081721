 (function(){
      'use strict';

      angular
         .module('app')
         .factory('ClientService', ClientService);

     ClientService.$inject = ['$http'];

     function ClientService($http){

         var service= {
            login:login,
           
         };

         return service;

         function login(vm)
         {

             return $http.post('/ajax/login', {data:{
             	username:vm.username,
             	password:vm.password,
             	region:vm.region,
             }})
             .then(getContentSuccess,getContentFailed);
             function getContentSuccess(data){ 
                 return data.data;
             }
             function getContentFailed(data){
                
                 return data;
             }
         }


     }
 })();