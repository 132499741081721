(function(){
     'use strict';

     angular
        .module('app')
        .factory('PostService', PostService);

    PostService.$inject = ['$http'];

    function PostService($http){

        var service= {
           getPost:getPost,
           getPostrand:getPostrand
        };

        return service;

        function getPost(vm){

            var config = {
                headers : {}
            }
            return $http.get('/ajax/getposts', { params : { lang_id:lang_id, offset:vm.offset, page:vm.page 
            } 
             }, config)
            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
            var config = {
                headers : {}
            }

        };

        function getPostrand(vm){

             var config = {
                headers : {}
            }
            return $http.get('/ajax/getpostrandom', { params : { lang_id:lang_id 
            } 
             }, config)
            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
            var config = {
                headers : {}
            }

        };
      
    }
})();