(function() {
    'use strict';


    angular.module('app',['ngSanitize','ui.bootstrap']).config(function($interpolateProvider){
            //console.log("mod");
    //$interpolateProvider.startSymbol('{[{').endSymbol('}]}');
	}).config(function($httpProvider) {
   		// $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
            //console.log("mod");
    	$httpProvider.defaults.headers.post['X-CSRF-Token'] = jQuery('meta[name="csrf-token"]').attr("content");
        $httpProvider.interceptors.push(['$q',function ($q) {
            //console.log("e");
             return {
             'request': function (config) {
                  var d = new Date();
                 var baseurl = '/';

                 var string = "ajax";
                 //console.log(config.url.indexOf(string));
                 //console.log(config.url);
                if(config.url.indexOf(string) !== -1){
                  //  config.url = baseurl+config.url;
                    //console.log(config);
                }
                 
                 return config || $q.when(config);
                 }
             }
         }]);
 	    $httpProvider.defaults.timeout = 30000;
	}).config(["$interpolateProvider",function($interpolateProvider){
        $interpolateProvider.startSymbol("[[");
        $interpolateProvider.endSymbol("]]");
    }]).filter('trustAsResourceUrl', ['$sce', function($sce) {
        return function(val) {
            return $sce.trustAsResourceUrl(val);
        };
    }]);
})();
 