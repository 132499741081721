// (function(){
//      'use strict';

//      angular
//         .module('app')
//         .factory('CurriculumService', CurriculumService);

//     CurriculumService.$inject = ['$http'];

//     function CurriculumService($http){

//         var service= {
//            sendCV:sendCV,
           
//         };

//         return service;

//         function sendCV(registro,callback)
//         {

//             var config = {
//                 headers : {
//                     'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
//                 }
//             }
//             var data = $.param({
//                registro:registro,
//                lang_id:lang_id
//             });
//             $http.post('/ajax/sendcv', data, config)
//             .then(getContentSuccess,getContentFailed);
//             function getContentSuccess(data){ 
//                 callback(data.data);
//             }
//             function getContentFailed(data){
                
//                 return false;
//             }
//         }

//     }
// })();