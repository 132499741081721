 (function(){
     'use strict';

 /**
 * @memberof app
 * @ngdoc controller
 * @name ClientController
 * @description
 * This is main controller
 **/ 

     angular
     .module('app')
     .controller('ClientController', ClientController);

     ClientController.$inject = ["ClientService"];

     function ClientController(ClientService)
     {

     	var vm = this;
         

         vm.client = ClientService;
         vm.errorLogin = false;
         vm.errorRecover = false;
         vm.goodRecover = false;

         vm.login = function()
         {
         	vm.errorLogin = false;
         	vm.errorRecover = false;

         	vm.client.login(vm).then(function(response)
         	{
         		if(response.recoverEmailSent){
         			vm.goodRecover = true;

         		}else{
         			vm.errorRecover = true;

         		}

         		if(response.success)
         		{
         			vm.errorLogin = false;
         			jQuery("#accessForm").submit();
         		}else
         			vm.errorLogin = true;
         	},
         	function(error)
         	{
         		vm.errorLogin = true;
         	}
         	)
         }


     }


 })();